import { computed, createApp } from 'vue';
import { setupI18n } from '@/shared/utils/i18n';
import GlobalKeyDownDirective from '@/shared/directives/globalKeyDown';
import Notifications from '@/shared/components/Notifications/Notifications.vue';
import { initHobbiiStarter } from '@/shared/instances/hobbii-starter';
import { apolloClientInit } from '@/shared/utils/apollo-client';
import { useUserStore } from '@/shared/pinia/modules/user';
import { ApolloClients } from '@vue/apollo-composable';
import { openLoginModal } from '@/shared/instances/login-modal';

const initNotifications = async () => {
  const mountId = 'notifications-app';
  const element = document.getElementById(mountId);

  if (!element || !window.hobbiiStore) return;

  await initHobbiiStarter();

  if (!window.hobbiiStore) {
    console.error('window.hobbiiStore not initialized.');
    return;
  }

  // NOTE: The `graphqlHost` could just as well come from `import.meta.env.VITE_HOBBII_GATEWAY` (as intended),
  // `apolloClientInit` though utilizes the userStore, so awaiting `initHobbiiStarter` is required.
  const graphqlHost = window.hobbiiStore.state.value.config.graphqlHost;
  const apolloClient = apolloClientInit({ graphqlHost });

  const userStore = useUserStore();
  const profile = computed(() => userStore.communityProfile);

  const i18n = await setupI18n(window.Shopify.locale);

  createApp(Notifications)
    .use(window.hobbiiStore)
    .use(i18n)
    .provide('profile', profile)
    .provide('initLogin', openLoginModal)
    .provide(ApolloClients, {
      gateway: apolloClient
    })
    .directive('global-keydown', GlobalKeyDownDirective)
    .mount(`#${mountId}`);
};

export { initNotifications };
