<template>
  <div
    ref="modalContainer"
    class="group fixed inset-0 z-40 hidden overflow-hidden [&.is-open]:flex"
    aria-labelledby="modal"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="fixed inset-0 z-10 bg-black opacity-0 transition-opacity group-[.is-open]:opacity-75"
      @click="onClose"
    ></div>
    <div
      class="relative z-20 m-auto flex max-h-screen w-11/12 min-w-[300px] max-w-2xl -translate-y-full flex-col opacity-0 shadow-xl transition-all duration-700 group-[.is-open]:translate-y-0 group-[.is-open]:opacity-100"
    >
      <button
        class="absolute -top-9 right-0 flex size-7 items-center justify-center rounded-full bg-gray text-xl"
        @click="onClose"
      >
        &times;
      </button>
      <div class="overflow-hidden rounded bg-white">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import modalEvents from '@/shared/constants/modal-events';

const modalContainer = ref(null);

const onClose = () => {
  modalContainer.value.classList.remove('is-open');
  setTimeout(() => modalContainer.value.classList.add('hidden'), 300);
  document.querySelector('body').classList.remove('modal-open');
};

const onOpen = () => {
  modalContainer.value.classList.remove('hidden');
  setTimeout(() => modalContainer.value.classList.add('is-open'), 1);
  document.querySelector('body').classList.add('modal-open');
};

onMounted(() => {
  modalContainer.value.addEventListener(modalEvents.open, () => onOpen());
  modalContainer.value.addEventListener(modalEvents.close, () => onClose());
});
</script>

<style scoped>
@import url(@/entrypoints/tailwind-ce.css);
</style>
