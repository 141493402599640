import { createApp } from 'vue';
import { apolloClientInit } from '../utils/apollo-client.js';
import { queryCountryData } from '../graphql/gateway/queries';
import { initHobbiiStarter } from './hobbii-starter.js';
import CountrySwitcher from '@/shared/components/UI/CountrySwitcher.vue';
import { setupI18n } from '@/shared/utils/i18n';

const initCountrySwitcher = async () => {
  const mountSelector = '#footer-country-switcher';
  const mountElement = document.querySelector(mountSelector);

  if (!mountElement) {
    console.error(`Element with id: "${mountSelector}" not found.`);
    return;
  }

  const cartCurrency = mountElement.dataset.cartCurrency;

  await initHobbiiStarter();

  try {
    const graphqlHost = window.hobbiiStore.state.value.config.graphqlHost;
    const apolloClient = apolloClientInit({ graphqlHost });

    const countryDataResponse = await apolloClient.query(queryCountryData());

    if (countryDataResponse.data) {
      const i18n = await setupI18n(window.Shopify.locale);

      createApp(CountrySwitcher, {
        countryConfigs: countryDataResponse.data.countryConfigs ?? [],
        cartCurrency
      })
        .use(i18n)
        .mount(mountSelector);
    }
  } catch (e) {
    // some old browsers are incompatible with queueMicrotask, used internally by query()
    // In this case the user will not be able to switch country.
  }
};

export { initCountrySwitcher };
