<script setup lang="ts">
import { ref } from 'vue';
import NotificationsList from './NotificationsList.vue';
import AppModal from '@/shared/components/UI/Legacy/AppModal.vue';
import IconBell from '@/shared/components/UI/Icons/IconBell.vue';
import IconBellRings from '@/shared/components/UI/Icons/IconBellRings.vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useGatewayNotifications } from '@/shared/composables/notifications';
import { getLanguageType } from '@/shared/utils/gateway/helpers';
import { useCommunityAuth } from '@/components/community/utils/composables';
import { LanguageType } from '@/__generated__/gateway/graphql';

const { t } = useTranslations();

const { profile } = useCommunityAuth();

const notificationsModal = ref<typeof AppModal | null>(null);

const { unseenMaxed } = useGatewayNotifications(
  getLanguageType(window.Shopify.locale, LanguageType.En)
);

const showNotifications = () => {
  notificationsModal.value?.openModal();
};
</script>

<template>
  <span
    v-if="profile"
    class="flex cursor-pointer flex-col items-center justify-center gap-px text-center text-sm md:gap-1"
    :title="t('community.notifications.text_notifications')"
    @click="showNotifications"
  >
    <span class="relative">
      <IconBellRings
        v-if="unseenMaxed"
        class="text-xl text-white md:text-3xl lg:text-gray-darker"
      />
      <IconBell
        v-else
        class="text-xl text-white md:text-3xl lg:text-gray-darker"
      />
      <span
        v-if="unseenMaxed"
        class="absolute -right-2 -top-1 flex h-5 min-w-5 items-center justify-center rounded-full bg-pink p-1 text-xxs font-bold leading-none text-white md:-right-2 md:-top-3 md:h-6 md:min-w-6 md:text-xs"
        >{{ unseenMaxed }}</span
      >
    </span>
    <span class="text-xxs text-white md:text-sm lg:text-gray-darkest">{{
      t('community.notifications.text_notifications')
    }}</span>
  </span>

  <AppModal
    ref="notificationsModal"
    transition="slide-right-fade"
    class-name="notifications-modal"
  >
    <template #header>
      <h1>{{ t('community.notifications.text_notifications') }}</h1>
    </template>
    <template #body>
      <NotificationsList />
    </template>
  </AppModal>
</template>
